import { Colors } from '@pretto/website/src/templates/blocs/rows/rowRates/types/colors.type'
import { Rates } from '@pretto/website/src/templates/blocs/rows/rowRates/types/rates'

import React from 'react'

import * as S from './HeroRates.styles'

export type HeroRatesProps = {
  colors: Colors
  ratesProps: Omit<Rates, 'style'>
  text: string | React.ReactNode
  title?: string
}

export const HeroRates: React.FC<HeroRatesProps> = ({ colors, ratesProps, text, title, ...props }) => (
  <S.NewBlocRow $componentColors={colors} {...props}>
    <S.Container>
      <S.Title>{title}</S.Title>

      <S.Text>{text}</S.Text>

      <S.RateSection
        style={{ formBackgroundColor: colors.formColor, ratesPerDurationBorder: colors.border }}
        {...ratesProps}
      />
    </S.Container>
  </S.NewBlocRow>
)
