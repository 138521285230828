import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'
import { RatesSection as RateSectionComponent } from '@pretto/website/src/templates/blocs/rows/rowRates/components/RatesSection'
import { Colors } from '@pretto/website/src/templates/blocs/rows/rowRates/types/colors.type'

import styled from 'styled-components'

type IsFormColorDarkPorps = {
  $componentColors: Colors
}

type RateSectionStyle = {
  style: {
    formBackgroundColor: string
  }
}

export const NewBlocRow = styled(NewBlocRowComponent)<IsFormColorDarkPorps>`
  ${grid()};
  background-color: ${({ $componentColors, theme }) => theme.colors[$componentColors.backgroundColor]};
  padding-bottom: ${g(1)};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: ${g(1)};
    background-color: ${({ theme, $componentColors }) => theme.colors[$componentColors.formColor]};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(3)};

    &::after {
      content: '';
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: ${g(3)};
      background-color: ${({ theme, $componentColors }) => theme.colors[$componentColors.formColor]};
    }
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-bottom: ${g(10)};

    &::after {
      content: none;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${column([1, 6])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([2, 12])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, 10])};
  }
`

export const Suptitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(2)};
  padding: 0 ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: unset;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.typos.heading3};
  margin-bottom: ${g(2)};
  padding: 0 ${g(2, 4)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: unset;
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.typos.body4};
  margin-bottom: ${g(3)};
  padding: 0 ${g(2, 4)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
    padding: unset;
  }
`

export const RateSection = styled(RateSectionComponent)<RateSectionStyle>`
  @media screen and (min-width: ${breakpoints.tablet}) {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      left: -100%;
      right: -100%;
      height: 75%;
      background-color: ${({ theme, style }) => theme.colors[style.formBackgroundColor]};
    }
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    &::before {
      content: none;
    }
  }
`

export const Legend = styled.div`
  ${({ theme }) => theme.typos.body4};
  margin-top: ${g(4)};
  padding: 0 ${g(2, 4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: unset;
  }
`
